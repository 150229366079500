<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
            <thead>
                <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                    <td
                        width="50%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Datos objetivos
                    </td>

                    <td
                        width="50%"
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Datos subjetivos
                    </td>
                </tr>
            </thead>
            <tbody :style='{"vertical-align":"top", "white-space": "pre-wrap"}'>
                <tr>
                    <td :style='estilos.tablaBorde'>
                        {{datos?datos.objetivos:''}}
                    </td>
                    <td :style='estilos.tablaBorde'>
                        {{datos?datos.subjetivos:''}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>